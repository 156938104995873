/* eslint-disable react/jsx-props-no-spreading */
import Head from 'next/head';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { ReactNode, useEffect } from 'react';
import { Provider } from 'next-auth/client';
import { useRouter } from 'next/router';

import { SWRConfig } from 'swr';

import * as Sentry from '@sentry/node';
import Error from 'next/error';
import { fetcher } from '../lib/fetch';

import * as gtag from '../lib/gtag';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

interface SentryAppProps extends AppProps {
  err: Error;
}

export const reportWebVitals = (metric: NextWebVitalsMetric): void => {
  gtag.event(metric);
};

const Application = ({
  Component,
  pageProps,
  err,
}: SentryAppProps): ReactNode => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <Provider session={pageProps.session}>
      <Head>
        <title>{process.env.NEXT_PUBLIC_DEFAULT_WINDOW_TITLE}</title>
        <meta
          name="description"
          content={process.env.NEXT_PUBLIC_DESCRIPTION}
        />
        <meta name="keywords" content={process.env.NEXT_PUBLIC_KEYWORDS} />

        {/* Android metadata */}
        <meta
          name="theme-color"
          content={process.env.NEXT_PUBLIC_PRIMARY_COLOR}
        />
        <meta name="mobile-web-app-capable" content="yes" />

        {/* Android icons */}
        <link href="/icons/icon-192x192.png" rel="icon" sizes="192x192" />
        <link href="/icons/icon-128x128.png" rel="icon" sizes="128x128" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="theme-color"
          content={process.env.NEXT_PUBLIC_PRIMARY_COLOR}
        />

        {/* iOS metadata */}
        <meta
          name="apple-mobile-web-app-title"
          content={process.env.NEXT_PUBLIC_APP_NAME}
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/icons/favicon.ico" />

        {/* iOS icons */}
        <link
          href="/icons/touch-icon-iphone.png"
          rel="apple-touch-icon"
          sizes="57x57"
        />
        <link
          href="/icons/touch-icon-ipad.png"
          rel="apple-touch-icon"
          sizes="76x76"
        />
        <link
          href="/icons/touch-icon-iphone-retina.png"
          rel="apple-touch-icon"
          sizes="120x120"
        />
        <link
          href="/icons/touch-icon-ipad-retina.png"
          rel="apple-touch-icon"
          sizes="152x152"
        />
        <link
          href="/icons/touch-icon-ipad-pro.png"
          rel="apple-touch-icon"
          sizes="167x167"
        />
        <link
          href="/icons/touch-icon-iphone-6-plus.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />

        {/* iOS Splash Screens */}
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/splash/launch-1136x640.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/splash/launch-2436x1125.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/splash/launch-1792x828.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/splash/launch-828x1792.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/splash/launch-1334x750.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/splash/launch-1242x2688.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/splash/launch-2208x1242.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/splash/launch-1125x2436.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/splash/launch-1242x2208.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/splash/launch-2732x2048.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/splash/launch-2688x1242.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/splash/launch-2224x1668.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/splash/launch-750x1334.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/splash/launch-2048x2732.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/splash/launch-2388x1668.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/splash/launch-1668x2224.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/splash/launch-640x1136.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/splash/launch-1668x2388.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/splash/launch-2048x1536.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/splash/launch-1536x2048.png"
        />

        {/* PWA Manifest */}
        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/icons/favicon.ico" />

        {/* Responsive Web Design */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <SWRConfig
        value={{
          suspense: true,
          refreshWhenOffline: true, // Prevent no-refresh on Windows 10 telemetry blocked
          refreshInterval: 3000,
          dedupingInterval: 1000,
          fetcher,
        }}
      >
        <Component {...pageProps} err={err} />
      </SWRConfig>
      {/* Fixes flash of unstyled content for first load (Chromium bug)
        https://github.com/ant-design/ant-design/issues/16037#issuecomment-509024637 */}
      <script> </script>
    </Provider>
  );
};

export default Application;
